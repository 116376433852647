<template>
  <div :class="isCollapse ? 'container':'container p-20'">
    <div v-if="isCollapse" class="qxc">全小宠</div>
    <div class="logo" v-else>
      <img :src="logo" alt />
      <!-- <img src="https://source.aidogcatpet.com/forit/logo.png" alt /> -->
    </div>
    <el-menu
      :default-active="activeIndex"
      active-text-color="#ffffff"
      router
      :collapse-transition="false"
      :collapse="isCollapse"
      @select="handleSelect"
    >
      <el-menu-item index="/home">
        <i class="el-icon-s-home"></i>
        <span>数据总览</span>
      </el-menu-item>
      <el-menu-item index="/order">
        <i class="el-icon-s-order"></i>
        <span>订单管理</span>
      </el-menu-item>
      <el-menu-item index="/product">
        <i class="el-icon-s-cooperation"></i>
        <span>平台商品</span>
      </el-menu-item>
      <el-menu-item index="/price">
        <i class="el-icon-s-release"></i>
        <span>商品控价</span>
      </el-menu-item>
      <el-menu-item index="/purchase">
        <i class="el-icon-s-claim"></i>
        <span>采购单据</span>
      </el-menu-item>
      <el-menu-item index="/stock">
        <i class="el-icon-menu"></i>
        <span>采购库存</span>
      </el-menu-item>
      <el-menu-item index="/share">
        <i class="el-icon-s-grid"></i>
        <span>共享库存</span>
      </el-menu-item>
      <el-menu-item index="/account">
        <i class="el-icon-user-solid"></i>
        <span>我的账户</span>
      </el-menu-item>
      <el-menu-item index="/contract">
        <i class="el-icon-s-flag"></i>
        <span>服务协议</span>
      </el-menu-item>
      <el-menu-item index="/message">
        <i class="el-icon-s-comment"></i>
        <span>系统消息</span>
      </el-menu-item>
    </el-menu>

    <!-- <div class="regional" @click="$router.push('/regional')">
      <img
        class="trophy"
        src="https://source.aidogcatpet.com/forit/trophy.png"
        alt
      />区域榜单
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/images/logo.png"),
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },

  },
  computed: {
    activeIndex: {
      get() {
        return this.$store.getters.activeIndex;
      },
      set() {},
    },
    isCollapse: {
      get() {
        return this.$store.getters.isCollapse;
      },
      set() {},
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.p-20 {
  padding: 0 20px;
}
.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  
  .qxc {
    background-color: #fff;
    color: #027afb;
    height: 50px;
    line-height: 50px;
    padding: 10px 10px 0;
    font-weight: 700;
    text-align: center;
    font-size: 23px;
  }
  .logo {
    width: 138px;
    height: 138px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .el-menu {
    height: 100%;
    // padding: 0 20px;
    border-right: none;
    box-sizing: border-box;
    overflow: hidden;

    .el-menu-item {
      border-radius: 6px;
      overflow: hidden;
      font-weight: 700;
      font-size: 16px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
  }
  .regional {
    background-color: #fff7db;
    color: #ff9400;
    height: 50px;
    box-sizing: border-box;
    width: 90%;
    position: relative;
    line-height: 50px;
    margin: 40px 0;
    padding-right: 20px;
    text-align: right;
    font-weight: 700;

    img {
      width: 90px;
      position: absolute;
      top: -40px;
      left: 0;
      z-index: 999;
    }
  }
  .is-active {
    background-color: #0078fb;
    color: #fff;
  }
}
</style>