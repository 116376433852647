<template>
  <div class="container">
    <el-container>
      <el-aside width="">
        <navMenu></navMenu>
      </el-aside>
      <el-container class="right-content">
        <el-header height="110px">
          <div class="icon-box" @click="changeCollapse">
            <i v-if="isCollapse" class="el-icon-d-arrow-right"></i>
            <i v-else class="el-icon-d-arrow-left"></i>
          </div>
          <headTitle
            @childByDialog="childByDialog"
            @childByPassword="childByPassword"
          ></headTitle>
        </el-header>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
    <ModifyInformation
      :dialogVisible="dialogVisible"
      @changeDialogVisible="setVisible"
    ></ModifyInformation>
    <ModifyPassword
      :passwordVisible="passwordVisible"
      @changePasswordVisible="setPasswordVisible"
    ></ModifyPassword>
    <Agreement :agreementVisible="agreementVisible"></Agreement>
    <el-dialog
      title="公告"
      :visible="noticeVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-image src="https://source.aidogcatpet.com/saas/notice.png">
      </el-image>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeNotice">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="公告"
      :visible="reminderVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>尊敬的各位用户：</div>
      <p>
        为了更好的提供平台服务，并符合"保税仓跨境商品发货规则"，本公司诚邀使用本平台的用户于2024年1月31日之前在"全小宠"系统中签署《全小宠电商平台使用协议2024》。
      </p>
      <p>
        逾期未签署协议的用户将会影响平台下单。对此给您带来的不便，请谅解！同时也非常感谢各位的配合！
        如您有任何问题，请咨询在线客服或与销售负责人联系。
      </p>

      <div>
        <div style="margin-top: 15px">
          <el-button @click="sendMessage()" type="primary"
            >发送《全小宠电商平台使用协议2024》</el-button
          >
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeMo">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import navMenu from "@/components/Nav1";
import headTitle from "@/components/Head";
import { Notification } from "element-ui";
import store from "@/store/index.js";
import ModifyInformation from "@/components/ModifyInformation";
import Agreement from "@/components/Agreement";
import ModifyPassword from "@/components/ModifyPassword";
export default {
  name: "layout1",
  components: {
    navMenu,
    headTitle,
    ModifyInformation,
    Agreement,
    ModifyPassword,
  },
  data() {
    return {
      dialogVisible: false,
      passwordVisible: false,
      noticeVisible: false,
      // reminderVisible :false,
      msg_id: "",
      radioList: [],
    };
  },

  computed: {
    activeIndex: {
      get() {
        return this.$store.getters.activeIndex;
      },
      set() {},
    },
    reminderVisible() {
      return store.getters.agreementVisible;
    },
    agreementVisible() {
      return store.state.agreementVisible2;
    },
    shopInfoData() {
      return JSON.parse(window.localStorage.getItem("shopInfo"));
    },
    isCollapse: {
      get() {
        return this.$store.getters.isCollapse;
      },
      set() {},
    },
  },
  created() {},
  mounted() {
    localStorage.setItem("msg_id", "");
    this.getStrongReminder();

    this.getNewMessage();
  },

  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    changeCollapse() {
      this.$store.dispatch("setCollapse", !this.isCollapse);
    },
    getNewMessage() {
      let that = this;
      let msg_id = localStorage.getItem("msg_id") || "";
      this.$http2
        .post("/merapi/message/remind")
        .then((res) => {
          let data = res.data.data;
          if (data.msg_id != msg_id && data.msg_id) {
            Notification.closeAll();
            localStorage.setItem("msg_id", data.msg_id);
            Notification.info({
              title: data.msg_title,
              type: "warning",
              duration: 0,
              position: "top-right",
              offset: 120,
              onClick: function () {
                if (that.$route.path != "/message") {
                  that.$router.push({ name: "message" });
                }
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeMo() {
      store.dispatch("setAgreementVisibleFlag", false);
    },
    sendMessage() {
      let phone = this.shopInfoData.shop_account;

      this.$http2
        .post("/merapi/shop/sms/send", { phone })
        .then((res) => {
          let msg =
            "《全小宠电商平台使用协议2024》以短信的形式下发至您当前登录的手机上，请尽快查阅并完成签署！";
          if (res.data.code == -1) {
            msg = res.data.msg;
          } else if (res.data.code == 0) {
          }

          this.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            type: "warning",
          })
            .then(() => {
              this.closeMo();
            })
            .catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err + "");
        });
    },
    closeNotice() {
      this.noticeVisible = false;
    },
    getStrongReminder() {
      this.$http2
        .post("/merapi/shop/strong/reminder")
        .then((res) => {
          if (res.data.code == -1) {
            if (
              this.$route.path == "/contract" ||
              this.$route.path == "/home"
            ) {
              store.dispatch("setAgreementVisibleFlag", true);
              localStorage.setItem("reminderVisible", 1);
            }
          } else if (res.data.code == 0) {
            store.dispatch("setAgreementVisibleFlag", false);
            localStorage.setItem("reminderVisible", 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setVisible(data) {
      // 修改信息组件传值
      this.dialogVisible = data;
    },
    childByDialog(data) {
      this.dialogVisible = data;
    },
    childByPassword(data) {
      console.log(data);
      this.passwordVisible = data;
    },

    setPasswordVisible(data) {
      // 修改密码组件传值
      this.passwordVisible = data;
    },
  },
};
</script>
<style scoped lang="less">
.container {
  .el-header {
    background-color: #f6f9fb;
    padding: 0 50px;
    position: relative;
    .icon-box {
      position: absolute;
      width: 35px;
      height: 35px;
      background-color: #fff;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;
      border-bottom-right-radius: 15px;
      color: #027afb;
      z-index: 9999;
    }
  }

  .el-main {
    background-color: #f6f9fb;
    // height: calc(100vh - 110px);
    // overflow-y: scroll;
    padding: 0 50px;
  }
}
</style>