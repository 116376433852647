<template>
  <div class="container">
    <div class="left">
      <i :class="icon"></i>
      {{ title }}
    </div>
    <div class="right">
      <!-- <el-date-picker
        v-model="value1"
        disabled
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker> -->
      <weatherView></weatherView>
      <el-dropdown>
        <span class="el-dropdown-link">
          <img :src="shopInfo.header_img" alt="" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="$router.push('/account')">
            <i class="iconfont iconicon-test"></i>
            查看店铺信息
          </el-dropdown-item>
          <el-dropdown-item @click.native="childClick">
            <i class="iconfont iconxiugai"></i>
            修改店铺信息
          </el-dropdown-item>
          <el-dropdown-item @click.native="childClickPassword">
            <i class="iconfont icondunpai"></i>
            修改密码
          </el-dropdown-item>
          <el-dropdown-item @click.native="layout">
            <i class="iconfont iconjieshu"></i>
            安全退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import weatherView from "@/components/WeatherView";
export default {
  name: "Head",
  data() {
    return {
      title: "数据总览",
      icon: "el-icon-s-home",
      titleList: [
        {
          title: "数据总览",
          path: "/home",
          icon: "el-icon-s-home",
        },
        {
          title: "订单管理",
          path: "/order",
          icon: "el-icon-s-order",
        },
        {
          title: "平台商品",
          path: "/product",
          icon: "el-icon-s-cooperation",
        },
        {
          title: "商品控价",
          path: "/price",
          icon: "el-icon-s-release",
        },
        {
          title: "采购单",
          path: "/purchase",
          icon: "el-icon-s-claim",
        },
        {
          title: "采购库存",
          path: "/stock",
          icon: "el-icon-menu",
        },
        {
          title: "共享库存",
          path: "/share",
          icon: "el-icon-s-grid",
        },
        {
          title: "我的账户",
          path: "/account",
          icon: "el-icon-user-solid",
        },
        {
          title: "服务协议",
          path: "/contract",
          icon: "el-icon-s-flag",
        },
        {
          title: "系统消息",
          path: "/message",
          icon: "el-icon-s-comment",
        },
        {
          title: "区域榜单",
          path: "/regional",
          icon: "el-icon-s-data",
        },
      ],
      shopInfo: {},
      value1: "",
    };
  },
  components: {
    weatherView,
  },
  methods: {
    childClick() {
      console.log(2)
      this.$emit("childByDialog", true);
    },
    childClickPassword() {
      this.$emit("childByPassword", true);
    },
    layout() {
      this.$http2
        .post("/merapi/login/logout")
        .then((res) => {
          this.$router.push("/login");
          window.localStorage.clear();
          this.$message({
            message: res.data.msg,
            type: "success",
            duration: 1000,
          });
        })
        .catch((err) => {
          return this.$message({ message: err, type: "error", duration: 1000 });
        });
    },
    getShopInfo() {
      // 获取商户信息

      this.$http2
        .post("/merapi/shop/info")
        .then((res) => {
          this.shopInfo = res.data.data;
        })
        .catch((err) => {
          return this.$message.error(err);
        });
    },
  },
  mounted() {
    this.getShopInfo();
    this.value1 = new Date();
  },
  computed: {
    activeIndex: {
      get() {
        return this.$store.getters.activeIndex;
      },
      set() {},
    },
  },
  watch: {
    activeIndex() {
      this.titleList.forEach((item, index) => {
        if (item.path === this.activeIndex) {
          this.title = item.title;
          this.icon = item.icon;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>

.container {
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;

  .left {
    font-size: 22px;
    color: #101010;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: SourceHanSansSC-bold;
    i {
      margin-right: 10px;
      color: #027afb;
      font-size: 30px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999999;
  }
  .right img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    border-radius: 50%;
  }
}
</style>