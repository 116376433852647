<template>
    <div id="weater">
        <div id="he-plugin-simple" ref="weather"></div>
        <remote-script src="https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0"></remote-script>
    </div>
</template>
<script>
window.WIDGET = {CONFIG: {
          modules: "01234",
          background: "1",
          tmpColor: "FFFFFF",
          tmpSize: "16",
          cityColor: "FFFFFF",
          citySize: "16",
          aqiColor: "FFFFFF",
          aqiSize: "16",
          weatherIconSize: "24",
          alertIconSize: "18",
          padding: "10px 10px 10px 10px",
          shadow: "0",
          language: "auto",
          borderRadius: "5",
          fixed: "false",
          vertical: "top",
          horizontal: "left",
          key: "d91014a829fa47559102f987841008d4",
        },};   // 将WIDGET前边加上window 否则读取不到此变量
import Vue from 'vue'
Vue.component('remote-script', {   // vue动态生成script (在html中当成组件来用)
    render: function (createElement) {
        var self = this;
        return createElement('script', {
            attrs: {
                type: 'text/javascript',
                src: this.src
            },
            on: {
                load: function (event) {
                    self.$emit('load', event);
                },
                error: function (event) {
                    self.$emit('error', event);
                },
                readystatechange: function (event) {
                    if (this.readyState == 'complete') {
                        self.$emit('load', event);
                    }
                }
            }
        });
    },
    props: {
        src: {
            type: String,
            required: true
        }
    }
})
export default {
    name:"Weather",
    data(){
        return {
           
        }
    },
}
</script>
<style lang="less" scoped>

</style>