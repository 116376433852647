<template>
  <div class="container">
    <el-dialog title="用户协议" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="agreementVisible" width="50%">
      <div class="content">
        <h3>《保税仓商品订单规则》</h3>
        <p>尊敬的各位商户：</p>
        <p> “福摩”和“荒野盛宴”两个品牌目前都是海外批量采购后入保税仓后进行发货。</p>
        <p>
          保税仓发货是指：经销商的货物从海外批量采购后，被存放或放置在保税区的仓库内，当顾客下单时，直接从国内的保税仓发货，通过国内的物流或者快递配送到顾客手中。保税仓的货物从入仓到出仓，全程受到海关的监管，规范且开放，极大保证了产品品质。<span
            class="font-red">同时购买正规保税仓的商品视同个人物品出区消费，严禁二次售卖，通过跨境电商模式购买产品的消费者必须符合自用原则，不允许进行二次售卖。</span>目前，我国每位公民购买保税仓及正规的海外直邮商品，每年限制额度是￥26000（不含税）。
        </p>
        <p>根据以上保税仓发货原则，我司为配合海关及保税仓严格控制刷单行为，不能按照如下规则执行或提交手续的，或可视为刷单，将影响后续发货或申报：</p>
        <p>1、	相同地址和信息的订购人每周不超过3单，且每个订单内商品不超过12KG。不符合规则的订单暂不发货。</p>
        <p>2、	订单报关后，被海关抽检到的订单需要配合提供真实的购物截图和购物链接，无法提供的会影响下次申报。</p>
        <p>3、	每天13点前下单当天会有物流单号，海关六日及法定节假日不发货（每周五13点后及假日的订单节假日后第一个工作日安排申报）</p>
        <p>4、	每天13点后已申报的订单，不能取消订单，当日已出物流单号的订单不再做物流拦截操作，订单发出后一律由最终销售方负责联系订购人与物流快递进行订单地址变更。<span
            class="font-red">（如发货3个工作日后依然无物流轨迹的订单可以申报海关进行报备后拦截到新的收货地址）</span></p>
        <p>5、	由于疫情等原因造成快递无法送达的地区，会在订单系统里标记为：“快递停发”。<span
            class="font-red">（订单信息中的收货区域请和实际收货区域一致，由于地址不统一造成无法送达的需自行处理。）</span></p>
        <p>6、	由于订购人身份信息错误或订购人每年限额超出当年额度的，将无法发货。</p>
        <h4>请遵循以上规则下单，并提前告知订购人知晓，感谢各位的配合！</h4>
        <h5 style="margin-top: 40px;">附件：海关及保税仓发货告知书</h5>
        <div style="width:100%;text-align: center;"><el-image fit="contain" src="https://source.aidogcatpet.com/saas/announcement.png"></el-image></div>
        <el-switch v-if="shopInfo.is_check != 1" v-model="value" active-color="#13ce66" active-text="同意《保税仓订单规则》，继续使用下单系统。" inactive-color="#ff4949">
        </el-switch>
      </div>
      <span slot="footer" class="dialog-footer" v-if="shopInfo.is_check != 1">
        <el-button @click="changeVisible">取 消</el-button>
        <el-button type="primary" @click="subAgreement">确认提交</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="changeVisible">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL as baseUrl } from '../request/request.config'
import store from '@/store/index.js'
export default {
  name: 'information',
  data() {
    return {
      baseUrl,
      value: false,


      storeInfo: {},

    }
  },
  props: {
    agreementVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    shopInfo() {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    },
    token: {
      get() {
        return window.localStorage.getItem('token') || ''
      }
    }
  },
  created() {

  },
  methods: {
    subAgreement() {
      this.$http2.post('/merapi/shop/set_agreement',{is_check: this.value? 1: 0}).then(res=> {
        if(res.data.status == 200) {
          this.$message.success(res.data.msg)
          let shop_info = JSON.parse(localStorage.getItem('shopInfo'))
          shop_info.is_check = 1
          localStorage.setItem('shopInfo',JSON.stringify(shop_info))
          store.dispatch('setAgreementVisibleFlag2', false)
          setTimeout(() => {
            location.reload()
          }, 2000);
          
        } else {
          this.$message.error(res.data.msg)
        }
        
        
      }).catch(e=> {
        this.$message.error(e)
      })
    },



    changeVisible() {
      store.dispatch('setAgreementVisibleFlag2', false)
    }
  }
}
</script>

<style  lang="less">
.font-red {
  color: red;
}

h3 {
  width: 100%;
  text-align: center;
}

p {
  text-indent: 2rem;
  line-height: 28px;
  margin: 5px 0;
}
</style>
